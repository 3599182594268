import React from 'react'
import 'twin.macro'

import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import ChapterItemCard from 'mx/handbook/chapter-item-card'

export interface Chapter {
  actionText: string
  description: string
  markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
  photos: {
    file: {
      title: string
      url: string
    }
  }[]
  slotTitle: string
  video?: {
    title: string
    embedUrl: string
  }
}

type ChaptersProps = {
  items: Chapter[]
  handleItemClick: (
    item: Chapter,
    setCurrentItem: React.Dispatch<React.SetStateAction<Chapter | null>>,
  ) => () => void
  setCurrentItem: React.Dispatch<React.SetStateAction<Chapter | null>>
}

const Chapters = ({
  items,
  handleItemClick,
  setCurrentItem,
}: ChaptersProps) => {
  return (
    <div tw="w-full max-w-screen-xl h-full max-h-full px-4 mx-auto">
      {items.length > 0 && (
        <>
          <div tw="grid grid-cols-1 gap-16 w-full md:grid-cols-2 xl:grid-cols-3 h-[max-content] justify-items-center">
            {items.map((item: Chapter) => (
              <ChapterItemCard
                key={item.slotTitle}
                item={item}
                onClick={handleItemClick(item, setCurrentItem)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Chapters
