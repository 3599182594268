import React from 'react'

import 'twin.macro'

import { BLOCKS } from '@contentful/rich-text-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { Chapter } from 'mx/handbook/chapters'

type ChapterCardProps = {
  item: Chapter
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const ChapterItemCard = ({ item, onClick }: ChapterCardProps) => {
  return (
    <div tw="w-full h-auto flex-col inline-flex">
      <div tw="w-full h-full bg-gray-100 border border-gray-100 flex-col justify-between items-center gap-4 inline-flex">
        <div tw="w-full p-3 pl-4 border-b border-gray-300 justify-start items-center gap-2.5 inline-flex">
          <h4 tw="text-gray-900 text-base font-medium leading-relaxed">
            {item.slotTitle}
          </h4>
        </div>
        <div tw="justify-center items-center flex">
          <img
            src={item.photos?.[0].file.url}
            alt={item.photos?.[0].file.title}
          />
        </div>
        <div tw="px-6 w-full gap-4 justify-start items-center inline-flex">
          <h3
            tw="text-left text-gray-900 text-3xl font-thin pb-4 leading-10 border-b border-gray-900 w-full"
            dangerouslySetInnerHTML={{
              __html: item.description ?? '',
            }}
          />
        </div>
        <div tw="px-6 px-6 w-full gap-4 justify-start items-center inline-flex">
          {renderRichText(item.markdown, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => {
                if (node.nodeType === 'paragraph') {
                  return (
                    <p tw="text-gray-600 text-sm font-light text-left pb-4 leading-snug border-b border-gray-300 w-full">
                      {children}
                    </p>
                  )
                }
              },
            },
          })}
        </div>
        <div tw="w-full h-9 pb-4 px-6 justify-start items-center inline-flex">
          {item.video ? (
            <button
              tw="text-left text-brown-600 text-base leading-relaxed"
              onClick={onClick}
            >
              <FontAwesomeIcon tw="pr-2" icon={['fal', 'camera-movie']} />
              {item.actionText}
            </button>
          ) : (
            <span tw="text-gray-400">{item.actionText}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChapterItemCard
