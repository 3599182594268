import React from 'react'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import * as Portal from '@radix-ui/react-portal'
import { AnimatePresence, motion } from 'framer-motion'
import { useMedia } from 'react-use'
import tw, { styled } from 'twin.macro'

import { Chapter } from 'mx/handbook/chapters'

const StyledOverlay = styled(DialogPrimitive.Overlay)(() => [
  tw`fixed inset-0 bg-black/25 z-40 flex flex-col justify-center items-center`,
])

const StyledContent = styled(DialogPrimitive.Content)(() => [
  tw`fixed inset-0 bg-white h-screen pb-0 rounded-sm w-full flex items-center focus:outline-none z-50 isolate`,
  tw`md:(w-[90%] max-w-[1440px] max-h-[730px] flex mx-auto -translate-y-2/4 top-1/2 overflow-hidden)`,
])

const DialogClose = DialogPrimitive.Close

const DialogContent = ({
  children,
  ...props
}: DialogPrimitive.DialogContentProps) => {
  const isMobile = useMedia('(max-width: 767px)')

  return (
    <Portal.Root>
      <StyledOverlay asChild forceMount>
        <motion.div
          id="contractorModalBackdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeIn' }}
        />
      </StyledOverlay>
      <StyledContent {...props} asChild forceMount>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              transform: 'translateY(20px)',
            },
            visible: {
              opacity: 1,
              transform: `translateY(${isMobile ? '0px' : '-50%'})`,
              transition: {
                duration: 0.25,
                ease: 'easeInOut',
              },
            },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {children}
        </motion.div>
      </StyledContent>
    </Portal.Root>
  )
}

type ChapterModalProps = {
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
  description?: string
  handleClose: () => void
  open: boolean
  item: Chapter
}
const ChapterModal = ({
  closeOnClickOutside = true,
  closeOnEscape = true,
  item,
  handleClose,
  open,
}: ChapterModalProps) => {
  return (
    <DialogPrimitive.Root open={open}>
      <AnimatePresence>
        {open ? (
          <DialogContent
            onEscapeKeyDown={closeOnEscape ? handleClose : undefined}
            onPointerDownOutside={closeOnClickOutside ? handleClose : undefined}
          >
            <DialogClose asChild onClick={handleClose}>
              <button tw="p-4 text-blue-900 absolute right-0 top-0 outline-none z-10">
                <Cross1Icon />
              </button>
            </DialogClose>
            <div tw="flex flex-col w-full h-56 md:h-full">
              <div tw="overflow-y-auto relative flex flex-col grow w-full">
                <div tw="flex-col items-center flex grow md:m-16">
                  <iframe
                    tw="w-full h-full"
                    title={item.video?.title}
                    src={item.video?.embedUrl}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </DialogContent>
        ) : null}
      </AnimatePresence>
    </DialogPrimitive.Root>
  )
}

export default ChapterModal
