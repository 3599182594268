import React, { useState } from 'react'
import 'twin.macro'

import { Chapter } from 'mx/handbook/chapters'
import { Section } from 'mx/section'

type RenderCallbackArgs = {
  currentItem: Chapter | null
  items: Chapter[]
  setCurrentItem: React.Dispatch<React.SetStateAction<Chapter | null>>
}

type ItemsLayoutProps = {
  children: (args: RenderCallbackArgs) => React.ReactNode
  items: Chapter[]
  title: string
}

const ItemsLayout = ({ children, items, title }: ItemsLayoutProps) => {
  const [currentItem, setCurrentItem] = useState<Chapter | null>(null)

  return (
    <Section tw="mt-20 md:mt-32" padding="none">
      <div tw="flex items-center py-4 pr-4 mb-16 md:mb-24">
        <h2 tw="shrink text-2xl italic text-gray-900 font-thin text-left px-4">
          {title}
        </h2>
        <div tw="grow h-px bg-brown-600"></div>
      </div>
      <div>{children({ currentItem, items, setCurrentItem })}</div>
    </Section>
  )
}

export default ItemsLayout
