import React, { useCallback, useEffect, useState } from 'react'

import 'twin.macro'
import SEO from 'components/shared/seo'
import { useHandbook } from 'content-queries/mx/handbook'
import fireEvent from 'context/tracking/events'
import SignUpHero from 'mx/components/sign-up-hero'
import ChapterModal from 'mx/handbook/chapter-modal'
import Chapters, { Chapter } from 'mx/handbook/chapters'
import ItemsLayout from 'mx/handbook/items-layout'
import Layout from 'mx/layout'
import { FormContactSource } from 'types/crm'
import { resourcesUrls } from 'views/utils'

const HandbookPage = ({ path }: { path: string }) => {
  const { data } = useHandbook()
  const [modalOpen, setModalOpen] = useState(false)

  const [
    {
      node: {
        sectionModules: [signUp, chapters],
      },
    },
  ] = data

  const items = chapters.contentSlots

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Handbook',
    })
    fireEvent({
      type: 'guide_download_started',
      guideName: 'Handbook',
    })
  }, [])

  const handleOpenModal = useCallback(
    (
      item: Chapter,
      setCurrentItem: React.Dispatch<React.SetStateAction<Chapter | null>>,
    ) =>
      () => {
        setCurrentItem(item)
        setModalOpen(true)
      },
    [],
  )

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  return (
    <Layout path={path}>
      <SignUpHero
        contactSource={FormContactSource.SIGNUP_HANDBOOK}
        data={signUp}
        guideName={'Handbook'}
        resourceUrl={resourcesUrls.projectHandbook}
      />
      <ItemsLayout
        title={chapters.moduleTitle}
        items={(items as Chapter[]).map((i) => ({
          ...i,
        }))}
      >
        {({ currentItem, items, setCurrentItem }) => (
          <>
            <Chapters
              items={items}
              handleItemClick={handleOpenModal}
              setCurrentItem={setCurrentItem}
            />
            <ChapterModal
              item={currentItem as Chapter}
              open={modalOpen}
              handleClose={handleCloseModal}
            />
          </>
        )}
      </ItemsLayout>
    </Layout>
  )
}

export default HandbookPage

export const Head = () => (
  <SEO
    description="Get ready to download your copy for less stress and more success - chapter by chapter."
    title="Handbook"
  />
)
